export const VideoPlayer = () => import('../../components/videoPlayer.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerStream = () => import('../../components/videoPlayerStream.vue' /* webpackChunkName: "components/video-player-stream" */).then(c => wrapFunctional(c.default || c))
export const HebrewHomePageExpertSlider = () => import('../../components/HebrewHomePage/ExpertSlider.vue' /* webpackChunkName: "components/hebrew-home-page-expert-slider" */).then(c => wrapFunctional(c.default || c))
export const HebrewHomePageHero = () => import('../../components/HebrewHomePage/Hero.vue' /* webpackChunkName: "components/hebrew-home-page-hero" */).then(c => wrapFunctional(c.default || c))
export const HebrewHomePageInfo = () => import('../../components/HebrewHomePage/Info.vue' /* webpackChunkName: "components/hebrew-home-page-info" */).then(c => wrapFunctional(c.default || c))
export const HebrewHomePageLocationSlider = () => import('../../components/HebrewHomePage/LocationSlider.vue' /* webpackChunkName: "components/hebrew-home-page-location-slider" */).then(c => wrapFunctional(c.default || c))
export const HebrewHomePagePartnerAdvantages = () => import('../../components/HebrewHomePage/PartnerAdvantages.vue' /* webpackChunkName: "components/hebrew-home-page-partner-advantages" */).then(c => wrapFunctional(c.default || c))
export const HebrewHomePagePromptConsultantForm = () => import('../../components/HebrewHomePage/PromptConsultantForm.vue' /* webpackChunkName: "components/hebrew-home-page-prompt-consultant-form" */).then(c => wrapFunctional(c.default || c))
export const HebrewHomePageServices = () => import('../../components/HebrewHomePage/Services.vue' /* webpackChunkName: "components/hebrew-home-page-services" */).then(c => wrapFunctional(c.default || c))
export const ArticleGetInTouchForm = () => import('../../components/article/GetInTouchForm.vue' /* webpackChunkName: "components/article-get-in-touch-form" */).then(c => wrapFunctional(c.default || c))
export const ArticleRenderHtml = () => import('../../components/article/RenderHtml.vue' /* webpackChunkName: "components/article-render-html" */).then(c => wrapFunctional(c.default || c))
export const CardsClientCard = () => import('../../components/cards/ClientCard.vue' /* webpackChunkName: "components/cards-client-card" */).then(c => wrapFunctional(c.default || c))
export const CardsExpertCard = () => import('../../components/cards/ExpertCard.vue' /* webpackChunkName: "components/cards-expert-card" */).then(c => wrapFunctional(c.default || c))
export const CardsExpertCardGermany = () => import('../../components/cards/ExpertCardGermany.vue' /* webpackChunkName: "components/cards-expert-card-germany" */).then(c => wrapFunctional(c.default || c))
export const CardsExpertCardItaly = () => import('../../components/cards/ExpertCardItaly.vue' /* webpackChunkName: "components/cards-expert-card-italy" */).then(c => wrapFunctional(c.default || c))
export const CardsGenericCard = () => import('../../components/cards/GenericCard.vue' /* webpackChunkName: "components/cards-generic-card" */).then(c => wrapFunctional(c.default || c))
export const CardsLocationCard = () => import('../../components/cards/LocationCard.vue' /* webpackChunkName: "components/cards-location-card" */).then(c => wrapFunctional(c.default || c))
export const CardsServiceCard = () => import('../../components/cards/ServiceCard.vue' /* webpackChunkName: "components/cards-service-card" */).then(c => wrapFunctional(c.default || c))
export const CareersHero = () => import('../../components/careers/Hero.vue' /* webpackChunkName: "components/careers-hero" */).then(c => wrapFunctional(c.default || c))
export const CareersInfo = () => import('../../components/careers/Info.vue' /* webpackChunkName: "components/careers-info" */).then(c => wrapFunctional(c.default || c))
export const CareersQuestions = () => import('../../components/careers/Questions.vue' /* webpackChunkName: "components/careers-questions" */).then(c => wrapFunctional(c.default || c))
export const FloatingAgent = () => import('../../components/floating/Agent.vue' /* webpackChunkName: "components/floating-agent" */).then(c => wrapFunctional(c.default || c))
export const Form = () => import('../../components/form/index.vue' /* webpackChunkName: "components/form" */).then(c => wrapFunctional(c.default || c))
export const HomepageExpertSlider = () => import('../../components/homepage/ExpertSlider.vue' /* webpackChunkName: "components/homepage-expert-slider" */).then(c => wrapFunctional(c.default || c))
export const HomepageHero = () => import('../../components/homepage/Hero.vue' /* webpackChunkName: "components/homepage-hero" */).then(c => wrapFunctional(c.default || c))
export const HomepageInfo = () => import('../../components/homepage/Info.vue' /* webpackChunkName: "components/homepage-info" */).then(c => wrapFunctional(c.default || c))
export const HomepageLocationSlider = () => import('../../components/homepage/LocationSlider.vue' /* webpackChunkName: "components/homepage-location-slider" */).then(c => wrapFunctional(c.default || c))
export const HomepagePartnerAdvantages = () => import('../../components/homepage/PartnerAdvantages.vue' /* webpackChunkName: "components/homepage-partner-advantages" */).then(c => wrapFunctional(c.default || c))
export const HomepagePromptConsultantForm = () => import('../../components/homepage/PromptConsultantForm.vue' /* webpackChunkName: "components/homepage-prompt-consultant-form" */).then(c => wrapFunctional(c.default || c))
export const HomepageServices = () => import('../../components/homepage/Services.vue' /* webpackChunkName: "components/homepage-services" */).then(c => wrapFunctional(c.default || c))
export const InputDubaiInput = () => import('../../components/input/DubaiInput.vue' /* webpackChunkName: "components/input-dubai-input" */).then(c => wrapFunctional(c.default || c))
export const InputDubaiPhoneInput = () => import('../../components/input/DubaiPhoneInput.vue' /* webpackChunkName: "components/input-dubai-phone-input" */).then(c => wrapFunctional(c.default || c))
export const InputPhoneInput = () => import('../../components/input/PhoneInput.vue' /* webpackChunkName: "components/input-phone-input" */).then(c => wrapFunctional(c.default || c))
export const InputSelectInput = () => import('../../components/input/SelectInput.vue' /* webpackChunkName: "components/input-select-input" */).then(c => wrapFunctional(c.default || c))
export const InputSimpleInput = () => import('../../components/input/SimpleInput.vue' /* webpackChunkName: "components/input-simple-input" */).then(c => wrapFunctional(c.default || c))
export const InputValidationInput = () => import('../../components/input/ValidationInput.vue' /* webpackChunkName: "components/input-validation-input" */).then(c => wrapFunctional(c.default || c))
export const LayoutsFooterComponent = () => import('../../components/layouts/FooterComponent.vue' /* webpackChunkName: "components/layouts-footer-component" */).then(c => wrapFunctional(c.default || c))
export const LayoutsFooterPartnersComponent = () => import('../../components/layouts/FooterPartnersComponent.vue' /* webpackChunkName: "components/layouts-footer-partners-component" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderComponent = () => import('../../components/layouts/HeaderComponent.vue' /* webpackChunkName: "components/layouts-header-component" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHebrewFooterComponent = () => import('../../components/layouts/HebrewFooterComponent.vue' /* webpackChunkName: "components/layouts-hebrew-footer-component" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHebrewHeaderComponent = () => import('../../components/layouts/HebrewHeaderComponent.vue' /* webpackChunkName: "components/layouts-hebrew-header-component" */).then(c => wrapFunctional(c.default || c))
export const ListPropertyBenefits = () => import('../../components/list-property/benefits.vue' /* webpackChunkName: "components/list-property-benefits" */).then(c => wrapFunctional(c.default || c))
export const ListPropertyHands = () => import('../../components/list-property/hands.vue' /* webpackChunkName: "components/list-property-hands" */).then(c => wrapFunctional(c.default || c))
export const ListPropertyHero = () => import('../../components/list-property/hero.vue' /* webpackChunkName: "components/list-property-hero" */).then(c => wrapFunctional(c.default || c))
export const ListPropertySellingOutLeasting = () => import('../../components/list-property/selling-out-leasting.vue' /* webpackChunkName: "components/list-property-selling-out-leasting" */).then(c => wrapFunctional(c.default || c))
export const ListPropertyWhy = () => import('../../components/list-property/why.vue' /* webpackChunkName: "components/list-property-why" */).then(c => wrapFunctional(c.default || c))
export const ListsellBrokers = () => import('../../components/listsell/Brokers.vue' /* webpackChunkName: "components/listsell-brokers" */).then(c => wrapFunctional(c.default || c))
export const ListsellClients = () => import('../../components/listsell/Clients.vue' /* webpackChunkName: "components/listsell-clients" */).then(c => wrapFunctional(c.default || c))
export const ListsellHero = () => import('../../components/listsell/Hero.vue' /* webpackChunkName: "components/listsell-hero" */).then(c => wrapFunctional(c.default || c))
export const ListsellPropertyForm = () => import('../../components/listsell/PropertyForm.vue' /* webpackChunkName: "components/listsell-property-form" */).then(c => wrapFunctional(c.default || c))
export const ListsellStepRoad = () => import('../../components/listsell/StepRoad.vue' /* webpackChunkName: "components/listsell-step-road" */).then(c => wrapFunctional(c.default || c))
export const ModalsConnectionModalWindows = () => import('../../components/modals/ConnectionModalWindows.vue' /* webpackChunkName: "components/modals-connection-modal-windows" */).then(c => wrapFunctional(c.default || c))
export const ModalsDubaiInsidersClub = () => import('../../components/modals/DubaiInsidersClub.vue' /* webpackChunkName: "components/modals-dubai-insiders-club" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalDefault = () => import('../../components/modals/ModalDefault.vue' /* webpackChunkName: "components/modals-modal-default" */).then(c => wrapFunctional(c.default || c))
export const ModalsQuestionModal = () => import('../../components/modals/QuestionModal.vue' /* webpackChunkName: "components/modals-question-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsRequestCallModal = () => import('../../components/modals/RequestCallModal.vue' /* webpackChunkName: "components/modals-request-call-modal" */).then(c => wrapFunctional(c.default || c))
export const PartnersCalculate = () => import('../../components/partners/calculate.vue' /* webpackChunkName: "components/partners-calculate" */).then(c => wrapFunctional(c.default || c))
export const PartnersDevelopers = () => import('../../components/partners/developers.vue' /* webpackChunkName: "components/partners-developers" */).then(c => wrapFunctional(c.default || c))
export const PartnersFaq = () => import('../../components/partners/faq.vue' /* webpackChunkName: "components/partners-faq" */).then(c => wrapFunctional(c.default || c))
export const PartnersGuarantee = () => import('../../components/partners/guarantee.vue' /* webpackChunkName: "components/partners-guarantee" */).then(c => wrapFunctional(c.default || c))
export const PartnersIntro = () => import('../../components/partners/intro.vue' /* webpackChunkName: "components/partners-intro" */).then(c => wrapFunctional(c.default || c))
export const PartnersJoin = () => import('../../components/partners/join.vue' /* webpackChunkName: "components/partners-join" */).then(c => wrapFunctional(c.default || c))
export const PartnersPartnerAgences = () => import('../../components/partners/partner-agences.vue' /* webpackChunkName: "components/partners-partner-agences" */).then(c => wrapFunctional(c.default || c))
export const PartnersRequestACall = () => import('../../components/partners/request-a-call.vue' /* webpackChunkName: "components/partners-request-a-call" */).then(c => wrapFunctional(c.default || c))
export const PartnersSlider = () => import('../../components/partners/slider.vue' /* webpackChunkName: "components/partners-slider" */).then(c => wrapFunctional(c.default || c))
export const PartnersSteps = () => import('../../components/partners/steps.vue' /* webpackChunkName: "components/partners-steps" */).then(c => wrapFunctional(c.default || c))
export const RoadshowAgents = () => import('../../components/roadshow/Agents.vue' /* webpackChunkName: "components/roadshow-agents" */).then(c => wrapFunctional(c.default || c))
export const RoadshowCooperationLogo = () => import('../../components/roadshow/CooperationLogo.vue' /* webpackChunkName: "components/roadshow-cooperation-logo" */).then(c => wrapFunctional(c.default || c))
export const RoadshowFooter = () => import('../../components/roadshow/Footer.vue' /* webpackChunkName: "components/roadshow-footer" */).then(c => wrapFunctional(c.default || c))
export const RoadshowHero = () => import('../../components/roadshow/Hero.vue' /* webpackChunkName: "components/roadshow-hero" */).then(c => wrapFunctional(c.default || c))
export const RoadshowProjects = () => import('../../components/roadshow/Projects.vue' /* webpackChunkName: "components/roadshow-projects" */).then(c => wrapFunctional(c.default || c))
export const RoadshowReasons = () => import('../../components/roadshow/Reasons.vue' /* webpackChunkName: "components/roadshow-reasons" */).then(c => wrapFunctional(c.default || c))
export const RoadshowGenericAgentsItaly = () => import('../../components/roadshow-generic/AgentsItaly.vue' /* webpackChunkName: "components/roadshow-generic-agents-italy" */).then(c => wrapFunctional(c.default || c))
export const RoadshowGenericBannerForm = () => import('../../components/roadshow-generic/BannerForm.vue' /* webpackChunkName: "components/roadshow-generic-banner-form" */).then(c => wrapFunctional(c.default || c))
export const RoadshowGenericFooterItaly = () => import('../../components/roadshow-generic/FooterItaly.vue' /* webpackChunkName: "components/roadshow-generic-footer-italy" */).then(c => wrapFunctional(c.default || c))
export const RoadshowGenericOurPartners = () => import('../../components/roadshow-generic/OurPartners.vue' /* webpackChunkName: "components/roadshow-generic-our-partners" */).then(c => wrapFunctional(c.default || c))
export const RoadshowGenericProjectsItaly = () => import('../../components/roadshow-generic/ProjectsItaly.vue' /* webpackChunkName: "components/roadshow-generic-projects-italy" */).then(c => wrapFunctional(c.default || c))
export const RoadshowGenericReasonsItaly = () => import('../../components/roadshow-generic/ReasonsItaly.vue' /* webpackChunkName: "components/roadshow-generic-reasons-italy" */).then(c => wrapFunctional(c.default || c))
export const RoadshowGermanyAgentsGermany = () => import('../../components/roadshow-germany/AgentsGermany.vue' /* webpackChunkName: "components/roadshow-germany-agents-germany" */).then(c => wrapFunctional(c.default || c))
export const RoadshowGermanyBannerForm = () => import('../../components/roadshow-germany/BannerForm.vue' /* webpackChunkName: "components/roadshow-germany-banner-form" */).then(c => wrapFunctional(c.default || c))
export const RoadshowGermanyFooterGermany = () => import('../../components/roadshow-germany/FooterGermany.vue' /* webpackChunkName: "components/roadshow-germany-footer-germany" */).then(c => wrapFunctional(c.default || c))
export const RoadshowGermanyOurPartners = () => import('../../components/roadshow-germany/OurPartners.vue' /* webpackChunkName: "components/roadshow-germany-our-partners" */).then(c => wrapFunctional(c.default || c))
export const RoadshowGermanyProjectsGermany = () => import('../../components/roadshow-germany/ProjectsGermany.vue' /* webpackChunkName: "components/roadshow-germany-projects-germany" */).then(c => wrapFunctional(c.default || c))
export const RoadshowGermanyReasonsGermany = () => import('../../components/roadshow-germany/ReasonsGermany.vue' /* webpackChunkName: "components/roadshow-germany-reasons-germany" */).then(c => wrapFunctional(c.default || c))
export const RoadshowItalyAgentsItaly = () => import('../../components/roadshow-italy/AgentsItaly.vue' /* webpackChunkName: "components/roadshow-italy-agents-italy" */).then(c => wrapFunctional(c.default || c))
export const RoadshowItalyBannerForm = () => import('../../components/roadshow-italy/BannerForm.vue' /* webpackChunkName: "components/roadshow-italy-banner-form" */).then(c => wrapFunctional(c.default || c))
export const RoadshowItalyFooterItaly = () => import('../../components/roadshow-italy/FooterItaly.vue' /* webpackChunkName: "components/roadshow-italy-footer-italy" */).then(c => wrapFunctional(c.default || c))
export const RoadshowItalyOurPartners = () => import('../../components/roadshow-italy/OurPartners.vue' /* webpackChunkName: "components/roadshow-italy-our-partners" */).then(c => wrapFunctional(c.default || c))
export const RoadshowItalyProjectsItaly = () => import('../../components/roadshow-italy/ProjectsItaly.vue' /* webpackChunkName: "components/roadshow-italy-projects-italy" */).then(c => wrapFunctional(c.default || c))
export const RoadshowItalyReasonsItaly = () => import('../../components/roadshow-italy/ReasonsItaly.vue' /* webpackChunkName: "components/roadshow-italy-reasons-italy" */).then(c => wrapFunctional(c.default || c))
export const SellFooter = () => import('../../components/sell/footer.vue' /* webpackChunkName: "components/sell-footer" */).then(c => wrapFunctional(c.default || c))
export const SellHeader = () => import('../../components/sell/header.vue' /* webpackChunkName: "components/sell-header" */).then(c => wrapFunctional(c.default || c))
export const SellHero = () => import('../../components/sell/hero.vue' /* webpackChunkName: "components/sell-hero" */).then(c => wrapFunctional(c.default || c))
export const SellHeroV2 = () => import('../../components/sell/heroV2.vue' /* webpackChunkName: "components/sell-hero-v2" */).then(c => wrapFunctional(c.default || c))
export const SlidersSimpleSlider = () => import('../../components/sliders/SimpleSlider.vue' /* webpackChunkName: "components/sliders-simple-slider" */).then(c => wrapFunctional(c.default || c))
export const SlidersSliderControlButton = () => import('../../components/sliders/SliderControlButton.vue' /* webpackChunkName: "components/sliders-slider-control-button" */).then(c => wrapFunctional(c.default || c))
export const SlidersSliderDefault = () => import('../../components/sliders/SliderDefault.vue' /* webpackChunkName: "components/sliders-slider-default" */).then(c => wrapFunctional(c.default || c))
export const StepComponent0 = () => import('../../components/stepComponent/stepComponent0.vue' /* webpackChunkName: "components/step-component0" */).then(c => wrapFunctional(c.default || c))
export const StepComponent0V2 = () => import('../../components/stepComponent/stepComponent0V2.vue' /* webpackChunkName: "components/step-component0-v2" */).then(c => wrapFunctional(c.default || c))
export const StepComponent1 = () => import('../../components/stepComponent/stepComponent1.vue' /* webpackChunkName: "components/step-component1" */).then(c => wrapFunctional(c.default || c))
export const StepComponent1V2 = () => import('../../components/stepComponent/stepComponent1V2.vue' /* webpackChunkName: "components/step-component1-v2" */).then(c => wrapFunctional(c.default || c))
export const StepComponent1Old = () => import('../../components/stepComponent/stepComponent1_old.vue' /* webpackChunkName: "components/step-component1-old" */).then(c => wrapFunctional(c.default || c))
export const StepComponent2 = () => import('../../components/stepComponent/stepComponent2.vue' /* webpackChunkName: "components/step-component2" */).then(c => wrapFunctional(c.default || c))
export const StepComponent2V2 = () => import('../../components/stepComponent/stepComponent2V2.vue' /* webpackChunkName: "components/step-component2-v2" */).then(c => wrapFunctional(c.default || c))
export const StepComponent2Old = () => import('../../components/stepComponent/stepComponent2_old.vue' /* webpackChunkName: "components/step-component2-old" */).then(c => wrapFunctional(c.default || c))
export const StepComponent3 = () => import('../../components/stepComponent/stepComponent3.vue' /* webpackChunkName: "components/step-component3" */).then(c => wrapFunctional(c.default || c))
export const StepComponent3V2 = () => import('../../components/stepComponent/stepComponent3V2.vue' /* webpackChunkName: "components/step-component3-v2" */).then(c => wrapFunctional(c.default || c))
export const StepComponentAnimation = () => import('../../components/stepComponent/stepComponentAnimation.vue' /* webpackChunkName: "components/step-component-animation" */).then(c => wrapFunctional(c.default || c))
export const UiCalendar = () => import('../../components/ui/Calendar.vue' /* webpackChunkName: "components/ui-calendar" */).then(c => wrapFunctional(c.default || c))
export const UiLanguageSelector = () => import('../../components/ui/LanguageSelector.vue' /* webpackChunkName: "components/ui-language-selector" */).then(c => wrapFunctional(c.default || c))
export const UiLanguagesSelect = () => import('../../components/ui/LanguagesSelect.vue' /* webpackChunkName: "components/ui-languages-select" */).then(c => wrapFunctional(c.default || c))
export const UiRating = () => import('../../components/ui/Rating.vue' /* webpackChunkName: "components/ui-rating" */).then(c => wrapFunctional(c.default || c))
export const UiTimeLine = () => import('../../components/ui/TimeLine.vue' /* webpackChunkName: "components/ui-time-line" */).then(c => wrapFunctional(c.default || c))
export const UiTimer = () => import('../../components/ui/Timer.vue' /* webpackChunkName: "components/ui-timer" */).then(c => wrapFunctional(c.default || c))
export const WidgetHiringWidget = () => import('../../components/widget/HiringWidget.vue' /* webpackChunkName: "components/widget-hiring-widget" */).then(c => wrapFunctional(c.default || c))
export const ModalsSpotmodalFifthStep = () => import('../../components/modals/spotmodal/FifthStep.vue' /* webpackChunkName: "components/modals-spotmodal-fifth-step" */).then(c => wrapFunctional(c.default || c))
export const ModalsSpotmodalFirstStep = () => import('../../components/modals/spotmodal/FirstStep.vue' /* webpackChunkName: "components/modals-spotmodal-first-step" */).then(c => wrapFunctional(c.default || c))
export const ModalsSpotmodalFourthStep = () => import('../../components/modals/spotmodal/FourthStep.vue' /* webpackChunkName: "components/modals-spotmodal-fourth-step" */).then(c => wrapFunctional(c.default || c))
export const ModalsSpotmodalModalWindow = () => import('../../components/modals/spotmodal/ModalWindow.vue' /* webpackChunkName: "components/modals-spotmodal-modal-window" */).then(c => wrapFunctional(c.default || c))
export const ModalsSpotmodalSecondStep = () => import('../../components/modals/spotmodal/SecondStep.vue' /* webpackChunkName: "components/modals-spotmodal-second-step" */).then(c => wrapFunctional(c.default || c))
export const ModalsSpotmodalSixthStep = () => import('../../components/modals/spotmodal/SixthStep.vue' /* webpackChunkName: "components/modals-spotmodal-sixth-step" */).then(c => wrapFunctional(c.default || c))
export const ModalsSpotmodalThirdStep = () => import('../../components/modals/spotmodal/ThirdStep.vue' /* webpackChunkName: "components/modals-spotmodal-third-step" */).then(c => wrapFunctional(c.default || c))
export const SellContact = () => import('../../components/sell/contact/index.vue' /* webpackChunkName: "components/sell-contact" */).then(c => wrapFunctional(c.default || c))
export const SellContactBuyers = () => import('../../components/sell/contact-buyers/index.vue' /* webpackChunkName: "components/sell-contact-buyers" */).then(c => wrapFunctional(c.default || c))
export const SellCta = () => import('../../components/sell/cta/index.vue' /* webpackChunkName: "components/sell-cta" */).then(c => wrapFunctional(c.default || c))
export const SellCustomers = () => import('../../components/sell/customers/index.vue' /* webpackChunkName: "components/sell-customers" */).then(c => wrapFunctional(c.default || c))
export const SellFaqAccordion = () => import('../../components/sell/faq/Accordion.vue' /* webpackChunkName: "components/sell-faq-accordion" */).then(c => wrapFunctional(c.default || c))
export const SellFaq = () => import('../../components/sell/faq/index.vue' /* webpackChunkName: "components/sell-faq" */).then(c => wrapFunctional(c.default || c))
export const SellHowItWorks = () => import('../../components/sell/how-it-works/index.vue' /* webpackChunkName: "components/sell-how-it-works" */).then(c => wrapFunctional(c.default || c))
export const SellWhySellWith = () => import('../../components/sell/why-sell-with/index.vue' /* webpackChunkName: "components/sell-why-sell-with" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
