import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _54e5b2da = () => interopDefault(import('../pages/al-marjan-island/index.vue' /* webpackChunkName: "pages/al-marjan-island/index" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6d1a8795 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "pages/careers" */))
const _56b43496 = () => interopDefault(import('../pages/dubai-insiders-club/index.vue' /* webpackChunkName: "pages/dubai-insiders-club/index" */))
const _a152568c = () => interopDefault(import('../pages/dubai-insiders-club-index/index.vue' /* webpackChunkName: "pages/dubai-insiders-club-index/index" */))
const _71be726a = () => interopDefault(import('../pages/dubai-insiders-club-v2/index.vue' /* webpackChunkName: "pages/dubai-insiders-club-v2/index" */))
const _64473592 = () => interopDefault(import('../pages/he-lp/index.vue' /* webpackChunkName: "pages/he-lp/index" */))
const _5284422e = () => interopDefault(import('../pages/invest-in-dubai.vue' /* webpackChunkName: "pages/invest-in-dubai" */))
const _6a23936b = () => interopDefault(import('../pages/investment-compass/index.vue' /* webpackChunkName: "pages/investment-compass/index" */))
const _4358a3ca = () => interopDefault(import('../pages/investment-opportunities/index.vue' /* webpackChunkName: "pages/investment-opportunities/index" */))
const _df34f4fe = () => interopDefault(import('../pages/list-your-property-in-dubai/index.vue' /* webpackChunkName: "pages/list-your-property-in-dubai/index" */))
const _86457480 = () => interopDefault(import('../pages/listsell.vue' /* webpackChunkName: "pages/listsell" */))
const _436eabfb = () => interopDefault(import('../pages/partners.vue' /* webpackChunkName: "pages/partners" */))
const _0f71be30 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _c9429f26 = () => interopDefault(import('../pages/roadshow.vue' /* webpackChunkName: "pages/roadshow" */))
const _152dd753 = () => interopDefault(import('../pages/roadshow-germany.vue' /* webpackChunkName: "pages/roadshow-germany" */))
const _1920237a = () => interopDefault(import('../pages/roadshow-italy.vue' /* webpackChunkName: "pages/roadshow-italy" */))
const _2fa32347 = () => interopDefault(import('../pages/sell-your-property/index.vue' /* webpackChunkName: "pages/sell-your-property/index" */))
const _375c4167 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _0cf3725b = () => interopDefault(import('../pages/video.vue' /* webpackChunkName: "pages/video" */))
const _6fbcd594 = () => interopDefault(import('../pages/dubai-insiders-club-index/order.vue' /* webpackChunkName: "pages/dubai-insiders-club-index/order" */))
const _b7d8b494 = () => interopDefault(import('../pages/sell-your-property/submit/index.vue' /* webpackChunkName: "pages/sell-your-property/submit/index" */))
const _5ab6fecc = () => interopDefault(import('../pages/sell-your-property/v2/index.vue' /* webpackChunkName: "pages/sell-your-property/v2/index" */))
const _49413503 = () => interopDefault(import('../pages/sell-your-property/v2/submit/index.vue' /* webpackChunkName: "pages/sell-your-property/v2/submit/index" */))
const _375eaf2d = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/al-marjan-island",
    component: _54e5b2da,
    name: "al-marjan-island___en"
  }, {
    path: "/ar",
    component: _e542f99c,
    name: "index___ar"
  }, {
    path: "/careers",
    component: _6d1a8795,
    name: "careers___en"
  }, {
    path: "/de",
    component: _e542f99c,
    name: "index___de"
  }, {
    path: "/dubai-insiders-club",
    component: _56b43496,
    name: "dubai-insiders-club___en"
  }, {
    path: "/dubai-insiders-club-index",
    component: _a152568c,
    name: "dubai-insiders-club-index___en"
  }, {
    path: "/dubai-insiders-club-v2",
    component: _71be726a,
    name: "dubai-insiders-club-v2___en"
  }, {
    path: "/es",
    component: _e542f99c,
    name: "index___es"
  }, {
    path: "/fr",
    component: _e542f99c,
    name: "index___fr"
  }, {
    path: "/he",
    component: _e542f99c,
    name: "index___he"
  }, {
    path: "/he-lp",
    component: _64473592,
    name: "he-lp___en"
  }, {
    path: "/hu",
    component: _e542f99c,
    name: "index___hu"
  }, {
    path: "/invest-in-dubai",
    component: _5284422e,
    name: "invest-in-dubai___en"
  }, {
    path: "/investment-compass",
    component: _6a23936b,
    name: "investment-compass___en"
  }, {
    path: "/investment-opportunities",
    component: _4358a3ca,
    name: "investment-opportunities___en"
  }, {
    path: "/ko",
    component: _e542f99c,
    name: "index___ko"
  }, {
    path: "/list-your-property-in-dubai",
    component: _df34f4fe,
    name: "list-your-property-in-dubai___en"
  }, {
    path: "/listsell",
    component: _86457480,
    name: "listsell___en"
  }, {
    path: "/nl",
    component: _e542f99c,
    name: "index___nl"
  }, {
    path: "/partners",
    component: _436eabfb,
    name: "partners___en"
  }, {
    path: "/privacy",
    component: _0f71be30,
    name: "privacy___en"
  }, {
    path: "/ro",
    component: _e542f99c,
    name: "index___ro"
  }, {
    path: "/roadshow",
    component: _c9429f26,
    name: "roadshow___en"
  }, {
    path: "/roadshow-germany",
    component: _152dd753,
    name: "roadshow-germany___en"
  }, {
    path: "/roadshow-italy",
    component: _1920237a,
    name: "roadshow-italy___en"
  }, {
    path: "/ru",
    component: _e542f99c,
    name: "index___ru"
  }, {
    path: "/sell-your-property",
    component: _2fa32347,
    name: "sell-your-property___en"
  }, {
    path: "/terms",
    component: _375c4167,
    name: "terms___en"
  }, {
    path: "/tr",
    component: _e542f99c,
    name: "index___tr"
  }, {
    path: "/video",
    component: _0cf3725b,
    name: "video___en"
  }, {
    path: "/ar/al-marjan-island",
    component: _54e5b2da,
    name: "al-marjan-island___ar"
  }, {
    path: "/ar/careers",
    component: _6d1a8795,
    name: "careers___ar"
  }, {
    path: "/ar/dubai-insiders-club",
    component: _56b43496,
    name: "dubai-insiders-club___ar"
  }, {
    path: "/ar/dubai-insiders-club-index",
    component: _a152568c,
    name: "dubai-insiders-club-index___ar"
  }, {
    path: "/ar/dubai-insiders-club-v2",
    component: _71be726a,
    name: "dubai-insiders-club-v2___ar"
  }, {
    path: "/ar/he-lp",
    component: _64473592,
    name: "he-lp___ar"
  }, {
    path: "/ar/invest-in-dubai",
    component: _5284422e,
    name: "invest-in-dubai___ar"
  }, {
    path: "/ar/investment-compass",
    component: _6a23936b,
    name: "investment-compass___ar"
  }, {
    path: "/ar/investment-opportunities",
    component: _4358a3ca,
    name: "investment-opportunities___ar"
  }, {
    path: "/ar/list-your-property-in-dubai",
    component: _df34f4fe,
    name: "list-your-property-in-dubai___ar"
  }, {
    path: "/ar/listsell",
    component: _86457480,
    name: "listsell___ar"
  }, {
    path: "/ar/partners",
    component: _436eabfb,
    name: "partners___ar"
  }, {
    path: "/ar/privacy",
    component: _0f71be30,
    name: "privacy___ar"
  }, {
    path: "/ar/roadshow",
    component: _c9429f26,
    name: "roadshow___ar"
  }, {
    path: "/ar/roadshow-germany",
    component: _152dd753,
    name: "roadshow-germany___ar"
  }, {
    path: "/ar/roadshow-italy",
    component: _1920237a,
    name: "roadshow-italy___ar"
  }, {
    path: "/ar/sell-your-property",
    component: _2fa32347,
    name: "sell-your-property___ar"
  }, {
    path: "/ar/terms",
    component: _375c4167,
    name: "terms___ar"
  }, {
    path: "/ar/video",
    component: _0cf3725b,
    name: "video___ar"
  }, {
    path: "/de/al-marjan-island",
    component: _54e5b2da,
    name: "al-marjan-island___de"
  }, {
    path: "/de/careers",
    component: _6d1a8795,
    name: "careers___de"
  }, {
    path: "/de/dubai-insiders-club",
    component: _56b43496,
    name: "dubai-insiders-club___de"
  }, {
    path: "/de/dubai-insiders-club-index",
    component: _a152568c,
    name: "dubai-insiders-club-index___de"
  }, {
    path: "/de/dubai-insiders-club-v2",
    component: _71be726a,
    name: "dubai-insiders-club-v2___de"
  }, {
    path: "/de/he-lp",
    component: _64473592,
    name: "he-lp___de"
  }, {
    path: "/de/invest-in-dubai",
    component: _5284422e,
    name: "invest-in-dubai___de"
  }, {
    path: "/de/investment-compass",
    component: _6a23936b,
    name: "investment-compass___de"
  }, {
    path: "/de/investment-opportunities",
    component: _4358a3ca,
    name: "investment-opportunities___de"
  }, {
    path: "/de/list-your-property-in-dubai",
    component: _df34f4fe,
    name: "list-your-property-in-dubai___de"
  }, {
    path: "/de/listsell",
    component: _86457480,
    name: "listsell___de"
  }, {
    path: "/de/partners",
    component: _436eabfb,
    name: "partners___de"
  }, {
    path: "/de/privacy",
    component: _0f71be30,
    name: "privacy___de"
  }, {
    path: "/de/roadshow",
    component: _c9429f26,
    name: "roadshow___de"
  }, {
    path: "/de/roadshow-germany",
    component: _152dd753,
    name: "roadshow-germany___de"
  }, {
    path: "/de/roadshow-italy",
    component: _1920237a,
    name: "roadshow-italy___de"
  }, {
    path: "/de/sell-your-property",
    component: _2fa32347,
    name: "sell-your-property___de"
  }, {
    path: "/de/terms",
    component: _375c4167,
    name: "terms___de"
  }, {
    path: "/de/video",
    component: _0cf3725b,
    name: "video___de"
  }, {
    path: "/dubai-insiders-club-index/order",
    component: _6fbcd594,
    name: "dubai-insiders-club-index-order___en"
  }, {
    path: "/es/al-marjan-island",
    component: _54e5b2da,
    name: "al-marjan-island___es"
  }, {
    path: "/es/careers",
    component: _6d1a8795,
    name: "careers___es"
  }, {
    path: "/es/dubai-insiders-club",
    component: _56b43496,
    name: "dubai-insiders-club___es"
  }, {
    path: "/es/dubai-insiders-club-index",
    component: _a152568c,
    name: "dubai-insiders-club-index___es"
  }, {
    path: "/es/dubai-insiders-club-v2",
    component: _71be726a,
    name: "dubai-insiders-club-v2___es"
  }, {
    path: "/es/he-lp",
    component: _64473592,
    name: "he-lp___es"
  }, {
    path: "/es/invest-in-dubai",
    component: _5284422e,
    name: "invest-in-dubai___es"
  }, {
    path: "/es/investment-compass",
    component: _6a23936b,
    name: "investment-compass___es"
  }, {
    path: "/es/investment-opportunities",
    component: _4358a3ca,
    name: "investment-opportunities___es"
  }, {
    path: "/es/list-your-property-in-dubai",
    component: _df34f4fe,
    name: "list-your-property-in-dubai___es"
  }, {
    path: "/es/listsell",
    component: _86457480,
    name: "listsell___es"
  }, {
    path: "/es/partners",
    component: _436eabfb,
    name: "partners___es"
  }, {
    path: "/es/privacy",
    component: _0f71be30,
    name: "privacy___es"
  }, {
    path: "/es/roadshow",
    component: _c9429f26,
    name: "roadshow___es"
  }, {
    path: "/es/roadshow-germany",
    component: _152dd753,
    name: "roadshow-germany___es"
  }, {
    path: "/es/roadshow-italy",
    component: _1920237a,
    name: "roadshow-italy___es"
  }, {
    path: "/es/sell-your-property",
    component: _2fa32347,
    name: "sell-your-property___es"
  }, {
    path: "/es/terms",
    component: _375c4167,
    name: "terms___es"
  }, {
    path: "/es/video",
    component: _0cf3725b,
    name: "video___es"
  }, {
    path: "/fr/al-marjan-island",
    component: _54e5b2da,
    name: "al-marjan-island___fr"
  }, {
    path: "/fr/careers",
    component: _6d1a8795,
    name: "careers___fr"
  }, {
    path: "/fr/dubai-insiders-club",
    component: _56b43496,
    name: "dubai-insiders-club___fr"
  }, {
    path: "/fr/dubai-insiders-club-index",
    component: _a152568c,
    name: "dubai-insiders-club-index___fr"
  }, {
    path: "/fr/dubai-insiders-club-v2",
    component: _71be726a,
    name: "dubai-insiders-club-v2___fr"
  }, {
    path: "/fr/he-lp",
    component: _64473592,
    name: "he-lp___fr"
  }, {
    path: "/fr/invest-in-dubai",
    component: _5284422e,
    name: "invest-in-dubai___fr"
  }, {
    path: "/fr/investment-compass",
    component: _6a23936b,
    name: "investment-compass___fr"
  }, {
    path: "/fr/investment-opportunities",
    component: _4358a3ca,
    name: "investment-opportunities___fr"
  }, {
    path: "/fr/list-your-property-in-dubai",
    component: _df34f4fe,
    name: "list-your-property-in-dubai___fr"
  }, {
    path: "/fr/listsell",
    component: _86457480,
    name: "listsell___fr"
  }, {
    path: "/fr/partners",
    component: _436eabfb,
    name: "partners___fr"
  }, {
    path: "/fr/privacy",
    component: _0f71be30,
    name: "privacy___fr"
  }, {
    path: "/fr/roadshow",
    component: _c9429f26,
    name: "roadshow___fr"
  }, {
    path: "/fr/roadshow-germany",
    component: _152dd753,
    name: "roadshow-germany___fr"
  }, {
    path: "/fr/roadshow-italy",
    component: _1920237a,
    name: "roadshow-italy___fr"
  }, {
    path: "/fr/sell-your-property",
    component: _2fa32347,
    name: "sell-your-property___fr"
  }, {
    path: "/fr/terms",
    component: _375c4167,
    name: "terms___fr"
  }, {
    path: "/fr/video",
    component: _0cf3725b,
    name: "video___fr"
  }, {
    path: "/he/al-marjan-island",
    component: _54e5b2da,
    name: "al-marjan-island___he"
  }, {
    path: "/he/careers",
    component: _6d1a8795,
    name: "careers___he"
  }, {
    path: "/he/dubai-insiders-club",
    component: _56b43496,
    name: "dubai-insiders-club___he"
  }, {
    path: "/he/dubai-insiders-club-index",
    component: _a152568c,
    name: "dubai-insiders-club-index___he"
  }, {
    path: "/he/dubai-insiders-club-v2",
    component: _71be726a,
    name: "dubai-insiders-club-v2___he"
  }, {
    path: "/he/he-lp",
    component: _64473592,
    name: "he-lp___he"
  }, {
    path: "/he/invest-in-dubai",
    component: _5284422e,
    name: "invest-in-dubai___he"
  }, {
    path: "/he/investment-compass",
    component: _6a23936b,
    name: "investment-compass___he"
  }, {
    path: "/he/investment-opportunities",
    component: _4358a3ca,
    name: "investment-opportunities___he"
  }, {
    path: "/he/list-your-property-in-dubai",
    component: _df34f4fe,
    name: "list-your-property-in-dubai___he"
  }, {
    path: "/he/listsell",
    component: _86457480,
    name: "listsell___he"
  }, {
    path: "/he/partners",
    component: _436eabfb,
    name: "partners___he"
  }, {
    path: "/he/privacy",
    component: _0f71be30,
    name: "privacy___he"
  }, {
    path: "/he/roadshow",
    component: _c9429f26,
    name: "roadshow___he"
  }, {
    path: "/he/roadshow-germany",
    component: _152dd753,
    name: "roadshow-germany___he"
  }, {
    path: "/he/roadshow-italy",
    component: _1920237a,
    name: "roadshow-italy___he"
  }, {
    path: "/he/sell-your-property",
    component: _2fa32347,
    name: "sell-your-property___he"
  }, {
    path: "/he/terms",
    component: _375c4167,
    name: "terms___he"
  }, {
    path: "/he/video",
    component: _0cf3725b,
    name: "video___he"
  }, {
    path: "/hu/al-marjan-island",
    component: _54e5b2da,
    name: "al-marjan-island___hu"
  }, {
    path: "/hu/careers",
    component: _6d1a8795,
    name: "careers___hu"
  }, {
    path: "/hu/dubai-insiders-club",
    component: _56b43496,
    name: "dubai-insiders-club___hu"
  }, {
    path: "/hu/dubai-insiders-club-index",
    component: _a152568c,
    name: "dubai-insiders-club-index___hu"
  }, {
    path: "/hu/dubai-insiders-club-v2",
    component: _71be726a,
    name: "dubai-insiders-club-v2___hu"
  }, {
    path: "/hu/he-lp",
    component: _64473592,
    name: "he-lp___hu"
  }, {
    path: "/hu/invest-in-dubai",
    component: _5284422e,
    name: "invest-in-dubai___hu"
  }, {
    path: "/hu/investment-compass",
    component: _6a23936b,
    name: "investment-compass___hu"
  }, {
    path: "/hu/investment-opportunities",
    component: _4358a3ca,
    name: "investment-opportunities___hu"
  }, {
    path: "/hu/list-your-property-in-dubai",
    component: _df34f4fe,
    name: "list-your-property-in-dubai___hu"
  }, {
    path: "/hu/listsell",
    component: _86457480,
    name: "listsell___hu"
  }, {
    path: "/hu/partners",
    component: _436eabfb,
    name: "partners___hu"
  }, {
    path: "/hu/privacy",
    component: _0f71be30,
    name: "privacy___hu"
  }, {
    path: "/hu/roadshow",
    component: _c9429f26,
    name: "roadshow___hu"
  }, {
    path: "/hu/roadshow-germany",
    component: _152dd753,
    name: "roadshow-germany___hu"
  }, {
    path: "/hu/roadshow-italy",
    component: _1920237a,
    name: "roadshow-italy___hu"
  }, {
    path: "/hu/sell-your-property",
    component: _2fa32347,
    name: "sell-your-property___hu"
  }, {
    path: "/hu/terms",
    component: _375c4167,
    name: "terms___hu"
  }, {
    path: "/hu/video",
    component: _0cf3725b,
    name: "video___hu"
  }, {
    path: "/ko/al-marjan-island",
    component: _54e5b2da,
    name: "al-marjan-island___ko"
  }, {
    path: "/ko/careers",
    component: _6d1a8795,
    name: "careers___ko"
  }, {
    path: "/ko/dubai-insiders-club",
    component: _56b43496,
    name: "dubai-insiders-club___ko"
  }, {
    path: "/ko/dubai-insiders-club-index",
    component: _a152568c,
    name: "dubai-insiders-club-index___ko"
  }, {
    path: "/ko/dubai-insiders-club-v2",
    component: _71be726a,
    name: "dubai-insiders-club-v2___ko"
  }, {
    path: "/ko/he-lp",
    component: _64473592,
    name: "he-lp___ko"
  }, {
    path: "/ko/invest-in-dubai",
    component: _5284422e,
    name: "invest-in-dubai___ko"
  }, {
    path: "/ko/investment-compass",
    component: _6a23936b,
    name: "investment-compass___ko"
  }, {
    path: "/ko/investment-opportunities",
    component: _4358a3ca,
    name: "investment-opportunities___ko"
  }, {
    path: "/ko/list-your-property-in-dubai",
    component: _df34f4fe,
    name: "list-your-property-in-dubai___ko"
  }, {
    path: "/ko/listsell",
    component: _86457480,
    name: "listsell___ko"
  }, {
    path: "/ko/partners",
    component: _436eabfb,
    name: "partners___ko"
  }, {
    path: "/ko/privacy",
    component: _0f71be30,
    name: "privacy___ko"
  }, {
    path: "/ko/roadshow",
    component: _c9429f26,
    name: "roadshow___ko"
  }, {
    path: "/ko/roadshow-germany",
    component: _152dd753,
    name: "roadshow-germany___ko"
  }, {
    path: "/ko/roadshow-italy",
    component: _1920237a,
    name: "roadshow-italy___ko"
  }, {
    path: "/ko/sell-your-property",
    component: _2fa32347,
    name: "sell-your-property___ko"
  }, {
    path: "/ko/terms",
    component: _375c4167,
    name: "terms___ko"
  }, {
    path: "/ko/video",
    component: _0cf3725b,
    name: "video___ko"
  }, {
    path: "/nl/al-marjan-island",
    component: _54e5b2da,
    name: "al-marjan-island___nl"
  }, {
    path: "/nl/careers",
    component: _6d1a8795,
    name: "careers___nl"
  }, {
    path: "/nl/dubai-insiders-club",
    component: _56b43496,
    name: "dubai-insiders-club___nl"
  }, {
    path: "/nl/dubai-insiders-club-index",
    component: _a152568c,
    name: "dubai-insiders-club-index___nl"
  }, {
    path: "/nl/dubai-insiders-club-v2",
    component: _71be726a,
    name: "dubai-insiders-club-v2___nl"
  }, {
    path: "/nl/he-lp",
    component: _64473592,
    name: "he-lp___nl"
  }, {
    path: "/nl/invest-in-dubai",
    component: _5284422e,
    name: "invest-in-dubai___nl"
  }, {
    path: "/nl/investment-compass",
    component: _6a23936b,
    name: "investment-compass___nl"
  }, {
    path: "/nl/investment-opportunities",
    component: _4358a3ca,
    name: "investment-opportunities___nl"
  }, {
    path: "/nl/list-your-property-in-dubai",
    component: _df34f4fe,
    name: "list-your-property-in-dubai___nl"
  }, {
    path: "/nl/listsell",
    component: _86457480,
    name: "listsell___nl"
  }, {
    path: "/nl/partners",
    component: _436eabfb,
    name: "partners___nl"
  }, {
    path: "/nl/privacy",
    component: _0f71be30,
    name: "privacy___nl"
  }, {
    path: "/nl/roadshow",
    component: _c9429f26,
    name: "roadshow___nl"
  }, {
    path: "/nl/roadshow-germany",
    component: _152dd753,
    name: "roadshow-germany___nl"
  }, {
    path: "/nl/roadshow-italy",
    component: _1920237a,
    name: "roadshow-italy___nl"
  }, {
    path: "/nl/sell-your-property",
    component: _2fa32347,
    name: "sell-your-property___nl"
  }, {
    path: "/nl/terms",
    component: _375c4167,
    name: "terms___nl"
  }, {
    path: "/nl/video",
    component: _0cf3725b,
    name: "video___nl"
  }, {
    path: "/ro/al-marjan-island",
    component: _54e5b2da,
    name: "al-marjan-island___ro"
  }, {
    path: "/ro/careers",
    component: _6d1a8795,
    name: "careers___ro"
  }, {
    path: "/ro/dubai-insiders-club",
    component: _56b43496,
    name: "dubai-insiders-club___ro"
  }, {
    path: "/ro/dubai-insiders-club-index",
    component: _a152568c,
    name: "dubai-insiders-club-index___ro"
  }, {
    path: "/ro/dubai-insiders-club-v2",
    component: _71be726a,
    name: "dubai-insiders-club-v2___ro"
  }, {
    path: "/ro/he-lp",
    component: _64473592,
    name: "he-lp___ro"
  }, {
    path: "/ro/invest-in-dubai",
    component: _5284422e,
    name: "invest-in-dubai___ro"
  }, {
    path: "/ro/investment-compass",
    component: _6a23936b,
    name: "investment-compass___ro"
  }, {
    path: "/ro/investment-opportunities",
    component: _4358a3ca,
    name: "investment-opportunities___ro"
  }, {
    path: "/ro/list-your-property-in-dubai",
    component: _df34f4fe,
    name: "list-your-property-in-dubai___ro"
  }, {
    path: "/ro/listsell",
    component: _86457480,
    name: "listsell___ro"
  }, {
    path: "/ro/partners",
    component: _436eabfb,
    name: "partners___ro"
  }, {
    path: "/ro/privacy",
    component: _0f71be30,
    name: "privacy___ro"
  }, {
    path: "/ro/roadshow",
    component: _c9429f26,
    name: "roadshow___ro"
  }, {
    path: "/ro/roadshow-germany",
    component: _152dd753,
    name: "roadshow-germany___ro"
  }, {
    path: "/ro/roadshow-italy",
    component: _1920237a,
    name: "roadshow-italy___ro"
  }, {
    path: "/ro/sell-your-property",
    component: _2fa32347,
    name: "sell-your-property___ro"
  }, {
    path: "/ro/terms",
    component: _375c4167,
    name: "terms___ro"
  }, {
    path: "/ro/video",
    component: _0cf3725b,
    name: "video___ro"
  }, {
    path: "/ru/al-marjan-island",
    component: _54e5b2da,
    name: "al-marjan-island___ru"
  }, {
    path: "/ru/careers",
    component: _6d1a8795,
    name: "careers___ru"
  }, {
    path: "/ru/dubai-insiders-club",
    component: _56b43496,
    name: "dubai-insiders-club___ru"
  }, {
    path: "/ru/dubai-insiders-club-index",
    component: _a152568c,
    name: "dubai-insiders-club-index___ru"
  }, {
    path: "/ru/dubai-insiders-club-v2",
    component: _71be726a,
    name: "dubai-insiders-club-v2___ru"
  }, {
    path: "/ru/he-lp",
    component: _64473592,
    name: "he-lp___ru"
  }, {
    path: "/ru/invest-in-dubai",
    component: _5284422e,
    name: "invest-in-dubai___ru"
  }, {
    path: "/ru/investment-compass",
    component: _6a23936b,
    name: "investment-compass___ru"
  }, {
    path: "/ru/investment-opportunities",
    component: _4358a3ca,
    name: "investment-opportunities___ru"
  }, {
    path: "/ru/list-your-property-in-dubai",
    component: _df34f4fe,
    name: "list-your-property-in-dubai___ru"
  }, {
    path: "/ru/listsell",
    component: _86457480,
    name: "listsell___ru"
  }, {
    path: "/ru/partners",
    component: _436eabfb,
    name: "partners___ru"
  }, {
    path: "/ru/privacy",
    component: _0f71be30,
    name: "privacy___ru"
  }, {
    path: "/ru/roadshow",
    component: _c9429f26,
    name: "roadshow___ru"
  }, {
    path: "/ru/roadshow-germany",
    component: _152dd753,
    name: "roadshow-germany___ru"
  }, {
    path: "/ru/roadshow-italy",
    component: _1920237a,
    name: "roadshow-italy___ru"
  }, {
    path: "/ru/sell-your-property",
    component: _2fa32347,
    name: "sell-your-property___ru"
  }, {
    path: "/ru/terms",
    component: _375c4167,
    name: "terms___ru"
  }, {
    path: "/ru/video",
    component: _0cf3725b,
    name: "video___ru"
  }, {
    path: "/sell-your-property/submit",
    component: _b7d8b494,
    name: "sell-your-property-submit___en"
  }, {
    path: "/sell-your-property/v2",
    component: _5ab6fecc,
    name: "sell-your-property-v2___en"
  }, {
    path: "/tr/al-marjan-island",
    component: _54e5b2da,
    name: "al-marjan-island___tr"
  }, {
    path: "/tr/careers",
    component: _6d1a8795,
    name: "careers___tr"
  }, {
    path: "/tr/dubai-insiders-club",
    component: _56b43496,
    name: "dubai-insiders-club___tr"
  }, {
    path: "/tr/dubai-insiders-club-index",
    component: _a152568c,
    name: "dubai-insiders-club-index___tr"
  }, {
    path: "/tr/dubai-insiders-club-v2",
    component: _71be726a,
    name: "dubai-insiders-club-v2___tr"
  }, {
    path: "/tr/he-lp",
    component: _64473592,
    name: "he-lp___tr"
  }, {
    path: "/tr/invest-in-dubai",
    component: _5284422e,
    name: "invest-in-dubai___tr"
  }, {
    path: "/tr/investment-compass",
    component: _6a23936b,
    name: "investment-compass___tr"
  }, {
    path: "/tr/investment-opportunities",
    component: _4358a3ca,
    name: "investment-opportunities___tr"
  }, {
    path: "/tr/list-your-property-in-dubai",
    component: _df34f4fe,
    name: "list-your-property-in-dubai___tr"
  }, {
    path: "/tr/listsell",
    component: _86457480,
    name: "listsell___tr"
  }, {
    path: "/tr/partners",
    component: _436eabfb,
    name: "partners___tr"
  }, {
    path: "/tr/privacy",
    component: _0f71be30,
    name: "privacy___tr"
  }, {
    path: "/tr/roadshow",
    component: _c9429f26,
    name: "roadshow___tr"
  }, {
    path: "/tr/roadshow-germany",
    component: _152dd753,
    name: "roadshow-germany___tr"
  }, {
    path: "/tr/roadshow-italy",
    component: _1920237a,
    name: "roadshow-italy___tr"
  }, {
    path: "/tr/sell-your-property",
    component: _2fa32347,
    name: "sell-your-property___tr"
  }, {
    path: "/tr/terms",
    component: _375c4167,
    name: "terms___tr"
  }, {
    path: "/tr/video",
    component: _0cf3725b,
    name: "video___tr"
  }, {
    path: "/ar/dubai-insiders-club-index/order",
    component: _6fbcd594,
    name: "dubai-insiders-club-index-order___ar"
  }, {
    path: "/ar/sell-your-property/submit",
    component: _b7d8b494,
    name: "sell-your-property-submit___ar"
  }, {
    path: "/ar/sell-your-property/v2",
    component: _5ab6fecc,
    name: "sell-your-property-v2___ar"
  }, {
    path: "/de/dubai-insiders-club-index/order",
    component: _6fbcd594,
    name: "dubai-insiders-club-index-order___de"
  }, {
    path: "/de/sell-your-property/submit",
    component: _b7d8b494,
    name: "sell-your-property-submit___de"
  }, {
    path: "/de/sell-your-property/v2",
    component: _5ab6fecc,
    name: "sell-your-property-v2___de"
  }, {
    path: "/es/dubai-insiders-club-index/order",
    component: _6fbcd594,
    name: "dubai-insiders-club-index-order___es"
  }, {
    path: "/es/sell-your-property/submit",
    component: _b7d8b494,
    name: "sell-your-property-submit___es"
  }, {
    path: "/es/sell-your-property/v2",
    component: _5ab6fecc,
    name: "sell-your-property-v2___es"
  }, {
    path: "/fr/dubai-insiders-club-index/order",
    component: _6fbcd594,
    name: "dubai-insiders-club-index-order___fr"
  }, {
    path: "/fr/sell-your-property/submit",
    component: _b7d8b494,
    name: "sell-your-property-submit___fr"
  }, {
    path: "/fr/sell-your-property/v2",
    component: _5ab6fecc,
    name: "sell-your-property-v2___fr"
  }, {
    path: "/he/dubai-insiders-club-index/order",
    component: _6fbcd594,
    name: "dubai-insiders-club-index-order___he"
  }, {
    path: "/he/sell-your-property/submit",
    component: _b7d8b494,
    name: "sell-your-property-submit___he"
  }, {
    path: "/he/sell-your-property/v2",
    component: _5ab6fecc,
    name: "sell-your-property-v2___he"
  }, {
    path: "/hu/dubai-insiders-club-index/order",
    component: _6fbcd594,
    name: "dubai-insiders-club-index-order___hu"
  }, {
    path: "/hu/sell-your-property/submit",
    component: _b7d8b494,
    name: "sell-your-property-submit___hu"
  }, {
    path: "/hu/sell-your-property/v2",
    component: _5ab6fecc,
    name: "sell-your-property-v2___hu"
  }, {
    path: "/ko/dubai-insiders-club-index/order",
    component: _6fbcd594,
    name: "dubai-insiders-club-index-order___ko"
  }, {
    path: "/ko/sell-your-property/submit",
    component: _b7d8b494,
    name: "sell-your-property-submit___ko"
  }, {
    path: "/ko/sell-your-property/v2",
    component: _5ab6fecc,
    name: "sell-your-property-v2___ko"
  }, {
    path: "/nl/dubai-insiders-club-index/order",
    component: _6fbcd594,
    name: "dubai-insiders-club-index-order___nl"
  }, {
    path: "/nl/sell-your-property/submit",
    component: _b7d8b494,
    name: "sell-your-property-submit___nl"
  }, {
    path: "/nl/sell-your-property/v2",
    component: _5ab6fecc,
    name: "sell-your-property-v2___nl"
  }, {
    path: "/ro/dubai-insiders-club-index/order",
    component: _6fbcd594,
    name: "dubai-insiders-club-index-order___ro"
  }, {
    path: "/ro/sell-your-property/submit",
    component: _b7d8b494,
    name: "sell-your-property-submit___ro"
  }, {
    path: "/ro/sell-your-property/v2",
    component: _5ab6fecc,
    name: "sell-your-property-v2___ro"
  }, {
    path: "/ru/dubai-insiders-club-index/order",
    component: _6fbcd594,
    name: "dubai-insiders-club-index-order___ru"
  }, {
    path: "/ru/sell-your-property/submit",
    component: _b7d8b494,
    name: "sell-your-property-submit___ru"
  }, {
    path: "/ru/sell-your-property/v2",
    component: _5ab6fecc,
    name: "sell-your-property-v2___ru"
  }, {
    path: "/sell-your-property/v2/submit",
    component: _49413503,
    name: "sell-your-property-v2-submit___en"
  }, {
    path: "/tr/dubai-insiders-club-index/order",
    component: _6fbcd594,
    name: "dubai-insiders-club-index-order___tr"
  }, {
    path: "/tr/sell-your-property/submit",
    component: _b7d8b494,
    name: "sell-your-property-submit___tr"
  }, {
    path: "/tr/sell-your-property/v2",
    component: _5ab6fecc,
    name: "sell-your-property-v2___tr"
  }, {
    path: "/ar/sell-your-property/v2/submit",
    component: _49413503,
    name: "sell-your-property-v2-submit___ar"
  }, {
    path: "/de/sell-your-property/v2/submit",
    component: _49413503,
    name: "sell-your-property-v2-submit___de"
  }, {
    path: "/es/sell-your-property/v2/submit",
    component: _49413503,
    name: "sell-your-property-v2-submit___es"
  }, {
    path: "/fr/sell-your-property/v2/submit",
    component: _49413503,
    name: "sell-your-property-v2-submit___fr"
  }, {
    path: "/he/sell-your-property/v2/submit",
    component: _49413503,
    name: "sell-your-property-v2-submit___he"
  }, {
    path: "/hu/sell-your-property/v2/submit",
    component: _49413503,
    name: "sell-your-property-v2-submit___hu"
  }, {
    path: "/ko/sell-your-property/v2/submit",
    component: _49413503,
    name: "sell-your-property-v2-submit___ko"
  }, {
    path: "/nl/sell-your-property/v2/submit",
    component: _49413503,
    name: "sell-your-property-v2-submit___nl"
  }, {
    path: "/ro/sell-your-property/v2/submit",
    component: _49413503,
    name: "sell-your-property-v2-submit___ro"
  }, {
    path: "/ru/sell-your-property/v2/submit",
    component: _49413503,
    name: "sell-your-property-v2-submit___ru"
  }, {
    path: "/tr/sell-your-property/v2/submit",
    component: _49413503,
    name: "sell-your-property-v2-submit___tr"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index___en"
  }, {
    path: "/ar/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___ar"
  }, {
    path: "/de/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___de"
  }, {
    path: "/es/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___es"
  }, {
    path: "/fr/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___fr"
  }, {
    path: "/he/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___he"
  }, {
    path: "/hu/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___hu"
  }, {
    path: "/ko/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___ko"
  }, {
    path: "/nl/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___nl"
  }, {
    path: "/ro/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___ro"
  }, {
    path: "/ru/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___ru"
  }, {
    path: "/tr/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___tr"
  }, {
    path: "/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
